import React, { useState, useRef, useEffect, useMemo, createRef } from "react";
import "../styles/main.web.css";
import "../styles/main.mobile.css";
import { handleHeaderScroll, parseText } from "../components/utils";
import anim from "../components/masonry/animation";

import Modal from "@mui/material/Modal";
import closeIcon from "../assets/img/close.png";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import ContactUs from "../components/contactus/contactus";
import { Footer } from "../components/footer/Footer";
import { BusinessInquery } from "../components/BusinessInquiry/BusinessInquery";
import { fetchWorkData } from "../service/api";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";

export const Work = () => {
  const acheive = useRef(null);
  const { data, isLoading, error } = useQuery("workData", fetchWorkData);
  const [modalData, setModalData] = useState(null);
  const [indexVal, setindexVal] = useState(null);
  const location = useLocation();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const [y, setY] = useState(0);
  const minscrollval = 100;

  const refs = useMemo(
    () =>
      data?.work_details.reduce((acc, _, index) => {
        acc[index] = {
          element: createRef(),
          image: createRef(),
        };
        return acc;
      }, {}),
    [data]
  );

  const handleScroll = (e) => {
    const top = e.target.scrollTop;
    const VH = window.innerHeight;

    handleHeaderScroll(y, top);
    setY(top);

    data?.work_details.forEach((_, index) => {
      const elementRef = refs[index]?.element?.current;
      const imageRef = refs[index]?.image?.current;

      if (elementRef && imageRef) {
        const scrollThreshold = VH * index + minscrollval;
        const elementClass = index % 2 === 0 ? "animate" : "animate opposite";
        const imageClass = `solution-img animate`;

        if (top > scrollThreshold) {
          elementRef.className = elementClass;
          imageRef.className = imageClass;
        } else {
          elementRef.className = index % 2 === 0 ? "" : "opposite";
          imageRef.className =
            index % 2 === 0 ? "solution-img" : "solution-img-opposite";
        }
      }
    });

    const businessInquiryElement = acheive.current;
    if (businessInquiryElement) {
      const rect = businessInquiryElement.getBoundingClientRect();
      if (rect.top <= VH * 0.55) {
        anim.wowanimatefadein(acheive, true);
      } else {
        anim.wowanimatefadeout(acheive, true);
      }
    }
  };

  const getImg = () => {
    return modalData?.work_banner?.url || "";
  };

  const getRelatedText = () => {
    if (!modalData) return null;

    const { work_subtitle, work_description, contact_us_title } = modalData;

    return (
      <p>
        <h3
          dangerouslySetInnerHTML={{
            __html: parseText(work_subtitle),
          }}
        />
        <>
          {hideContent !== indexVal + 1 && (
            <div
              dangerouslySetInnerHTML={{
                __html: parseText(work_description),
              }}
            />
          )}
          <ContactUs
            title={contact_us_title}
            setHideContent={setHideContent}
            index={indexVal + 1}
          ></ContactUs>
        </>
      </p>
    );
  };

  const isMacintosh = () => {
    return navigator.platform.indexOf("Mac") > -1;
  };

  const closeModal = () => {
    setOpen(false);
  };

  function clickHandler(el, ind) {
    setModalData(el);
    setindexVal(ind);
    setOpen(true);
  }

  // contactus
  const [showContent, setShowContent] = useState(true);
  const [hideContent, setHideContent] = useState(false);

  const normalizeText = (text) => {
    return text
      .replace(/\*\*/g, "")
      .replace(/[\u201C\u201D\u0022\u0027]/g, "")
      .replace(/\s+/g, " ")
      .trim()
      .toLowerCase();
  };

  useEffect(() => {
    const scrollToMatch = () => {
      const alternativeText = location?.state?.data?.alternativeText;
      const caption = location?.state?.data?.caption;

      if (data?.work_details && alternativeText && refs) {
        const normalizedAlternativeText = normalizeText(
          alternativeText || caption
        );

        data?.work_details?.forEach((el, ind) => {
          if (normalizeText(el?.work_subtitle) === normalizedAlternativeText) {
            const elementRef = refs[ind]?.element?.current;
            if (elementRef) {
              elementRef.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }
        });
      }
    };

    if (data) {
      scrollToMatch();
    }
  }, [data, location?.state?.data?.alternativeText, refs]);

  return (
    <div id="home-root">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal-content-wrapper">
          <div className="close-icon">
            <img onClick={closeModal} src={closeIcon} alt="Close" />
          </div>
          <LazyLoadImage
            effect="blur"
            width={"100%"}
            src={getImg()}
            alt="Work banner"
          />
          <div className="modal-text-wrapper">{getRelatedText()}</div>
        </div>
      </Modal>

      <div
        onScroll={handleScroll}
        className={
          isMacintosh()
            ? "container both-scroll both-mandatory align-start"
            : "container"
        }
      >
        <header className="work-banner fullpage element">
          <div>
            <div className="colorfultext">{data?.work_banner_title}</div>
            <h2
              className="web-only"
              dangerouslySetInnerHTML={{
                __html: parseText(data?.work_banner_subtitle),
              }}
            />
            <h2
              className="mobile-only"
              dangerouslySetInnerHTML={{
                __html: parseText(data?.work_banner_subtitle),
              }}
            />
          </div>
        </header>
        {data?.work_details?.map((el, ind) => (
          <div
            key={ind}
            className={`work-details-child ${
              ind % 2 === 0 ? "odd-child" : "even-child"
            } fullpage element`}
          >
            <div onClick={() => clickHandler(el, ind)} className="mobile-only">
              <h3
                style={{ pointerEvents: "none" }}
                dangerouslySetInnerHTML={{
                  __html: parseText(el?.work_subtitle),
                }}
              />
            </div>
            {ind % 2 !== 0 && (
              <div className="solution-img" ref={refs[ind]?.image}>
                <img
                  className={`lazy-images-${ind % 2 === 0 ? "odd" : "even"}`}
                  src={el?.work_banner?.url}
                  style={ind === 5 ? { transform: "translateX(-400px)" } : {}}
                  alt={`Work ${ind + 1}`}
                />
              </div>
            )}
            <div className="solution-description web-only">
              <p
                ref={refs[ind]?.element}
                className={ind % 2 === 0 ? "" : "opposite"}
              >
                <div
                  className="industry-info"
                  dangerouslySetInnerHTML={{
                    __html: parseText(el?.work_title),
                  }}
                />
                <h3
                  dangerouslySetInnerHTML={{
                    __html: parseText(el?.work_subtitle),
                  }}
                />
                <br />
                {hideContent !== ind + 1 && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: parseText(el?.work_description),
                    }}
                  />
                )}
                <ContactUs
                  title={el?.contact_us_title || el?.work_title}
                  setHideContent={setHideContent}
                  index={ind + 1}
                />
              </p>
            </div>
            {ind % 2 === 0 && (
              <div className="solution-img" ref={refs[ind]?.image}>
                <img
                  className={`lazy-images-${ind % 2 === 0 ? "odd" : "even"}`}
                  src={el?.work_banner?.url}
                  alt={`Work ${ind + 1}`}
                />
              </div>
            )}
          </div>
        ))}
        <BusinessInquery acheive={acheive} />
        <Footer />
      </div>
    </div>
  );
};
