import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

export const fetchStrapiData = async (endpoint) => {
  try {
    const response = await api.get(endpoint);
    return response.data;
  } catch (error) {
    if (error.response) {
      console.error("Error Response:", error.response);
      throw new Error(
        `Failed to fetch data: ${error.response.data.message || error.message}`
      );
    } else if (error.request) {
      console.error("No response from server:", error.request);
      throw new Error("No response from server. Please try again later.");
    } else {
      console.error("Error", error.message);
      throw new Error("Something went wrong. Please try again.");
    }
  }
};

export const fetchHomeData = async () => {
  const response = await fetchStrapiData(
    "api/home?populate[banner][populate]=*&populate[ecosystem][populate][types][populate]=*&populate[products][populate][product_image][populate]=*"
  );
  return response?.data;
};

export const fetchAboutData = async () => {
  const response = await fetchStrapiData(
    "api/about?populate[development_flow][populate][development_flow][populate]=*&populate[industries_image]=*&populate[reasons_image]=*&populate[reasons_list]=*"
  );
  return response?.data;
};

export const fetchWorkData = async () => {
  const response = await fetchStrapiData(
    "api/work?populate[work_details][populate][work_banner][populate]=*"
  );
  return response?.data;
};

export const fetchContactData = async () => {
  const response = await fetchStrapiData(
    "api/contact?populate[business_set][populate][choices][populate]=*&populate[hiring_set][populate][choices][populate]=*&populate[business_email_set][populate]=*&populate[hiring_email_set][populate]=*&populate[questionnaire][populate][choices][populate]=*"
  );
  return response?.data;
};

export const fetchBusinessData = async () => {
  const response = await fetchStrapiData(
    "api/business?populate[concepts][populate]=*"
  );
  return response?.data;
};
