import React, { useEffect, useState } from "react";
import "../styles/main.web.css";
import "../styles/main.mobile.css";
import VerticalLinearStepper from "../components/stepper";
import Fade from "@mui/material/Fade";
import { Footer } from "../components/footer/Footer";
import { handleHeaderScroll, parseText } from "../components/utils";

export const Contact = () => {
  const [level, setLevel] = useState([0]);
  const [answer, setAnswer] = useState({});
  const [submit, setSubmit] = useState(false);
  const [data, setData] = useState();

  const movetonext = (obj) => {
    console.log(obj.followquestionid);
    if (level.length < 5) {
      setLevel((oldArray) => [...oldArray, obj.followquestionid]);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const header = document.getElementById("header");
      if (window.scrollY > 500) {
        header.style.backgroundColor = "#131313";
      } else {
        header.style.backgroundColor = "transparent";
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.documentElement.style.overflow = "hidden";

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [y, setY] = useState(0);

  const handleScroll = (e) => {
    const top = e.target.scrollTop;
    handleHeaderScroll(y, top);
    setY(top);
  };

  return (
    <div
      className="container both-scroll both-mandatory align-start"
      onScroll={handleScroll}
    >
      <Fade in={!submit} timeout={800}>
        <header className="contact-banner element">
          <div>
            <div
              className="colorfultext"
              dangerouslySetInnerHTML={{
                __html: parseText(data?.title),
              }}
            />
            <h2
              dangerouslySetInnerHTML={{
                __html: parseText(data?.sub_title),
              }}
            />
          </div>
        </header>
      </Fade>
      <div className="custom-questionnaire element">
        {level.map((val, index) => (
          <VerticalLinearStepper
            key={index}
            submit={submit}
            setSubmit={setSubmit}
            setanswer={setAnswer}
            answer={answer}
            level={val}
            movetonext={movetonext}
            length={level.length - 1}
            setData={setData}
          />
        ))}
      </div>
      <Footer />
    </div>
  );
};
