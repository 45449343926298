import React from "react";
import "../../styles/main.web.css";
import "../../styles/main.mobile.css";

export const Footer = () => {
  return (
    <>
      <footer className="element">
        <div className="footer-contact">
          <div>© 2021-22 RHOMBUZ LLC. All Rights Reserved</div>
          <a href="mailto:hello@rhombuz.io" className="mailbox">
            hello@rhombuz.io
          </a>
        </div>
      </footer>
    </>
  );
};
