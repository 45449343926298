export const isScrollingup = (prevscrollval, newscrollval) => {
  return prevscrollval > newscrollval;
};

export const handleHeaderScroll = (y, e, elem) => {
  console.log(y);
  const headerElement = document.getElementById("header");

  if (!headerElement) return;

  if (isScrollingup(y, e) && y > 100) {
    headerElement.className = "roll-downheader";
  } else if (!isScrollingup(y, e) && y > 100) {
    headerElement.className = "roll-upheader";
  }

  if (y < 100) {
    headerElement.className = "";
  }
};

export const parseText = (text) => {
  return text?.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>")
    .replace(/\n\n/g, "<br /><br />")
    .replace(/\n/g, "<br />");
};
