import React, { useState, useEffect, useRef } from "react";
import "../styles/main.web.css";
import "../styles/main.mobile.css";
import { Footer } from "../components/footer/Footer";
import { handleHeaderScroll, parseText } from "../components/utils";
import { BusinessInquery } from "../components/BusinessInquiry/BusinessInquery";
import anim from "../components/masonry/animation";
import { fetchAboutData } from "../service/api";
import Markdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

export const About = () => {
  const acheive = useRef(null);
  const [scrollStates, setScrollStates] = useState([]);
  const { data, isLoading, error } = useQuery("aboutData", fetchAboutData);
  const navigate = useNavigate();

  const [y, setY] = useState(0);
  const [currdirforward, setcurrdirforward] = useState("forward");
  var prevdir = "back";

  useEffect(() => {
    if (data?.development_flow?.development_flow.length) {
      const numOfItems = data.development_flow.development_flow.length;
      const newScrollStates = Array(numOfItems).fill(false);
      setScrollStates(newScrollStates);

      anim.setAnimationScrolldelay(acheive);
      document.documentElement.style.overflowY = "scroll";
    }

    return () => {
      document.documentElement.style.overflow = "hidden";
    };
  }, [data]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const viewHeight = window.innerHeight;
      const approxliwidth = 350;
      const scrollPointBase = viewHeight * 1.8;

      const updatedScrollStates = scrollStates.map((_, index) => {
        return scrollY > scrollPointBase + approxliwidth * index;
      });

      setScrollStates(updatedScrollStates);

      if (y - window.screenY > 50) setcurrdirforward("forward");
      else if (y - window.screenY < -20) setcurrdirforward("back");

      if (Math.abs(y - window.scrollY) > 100 && currdirforward !== prevdir) {
        handleHeaderScroll(y, window.scrollY);
        setY(window.scrollY);
        prevdir = currdirforward;
      }

      const businessInquiryElement = acheive.current;
      if (businessInquiryElement) {
        const rect = businessInquiryElement.getBoundingClientRect();
        if (rect.top <= viewHeight * 0.55) {
          anim.wowanimatefadein(acheive, true);
        } else {
          anim.wowanimatefadeout(acheive, true);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollStates, y, currdirforward]);

  const toContact = () => {
    navigate("/contact");
  };

  return (
    <div id="home-root">
      <header className="work-banner">
        <div>
          <div
            className="colorfultext"
            dangerouslySetInnerHTML={{
              __html: parseText(data?.about_banner_title),
            }}
          />
          <h2
            className="web-only"
            dangerouslySetInnerHTML={{
              __html: parseText(data?.about_banner_subtitle),
            }}
          />
          <h2
            className="mobile-only"
            dangerouslySetInnerHTML={{
              __html: parseText(data?.about_banner_subtitle),
            }}
          />
        </div>
      </header>
      <div className="white-bg black-rhombuz">
        <div
          className="seperator"
          style={{
            height: `${
              120 + data?.development_flow?.development_flow.length * 65
            }vh`,
          }}
        ></div>
        <section className="fullpage element content-section lefttext">
          <div className="flex-row">
            <div>
              <p
                className="form-function-text"
                dangerouslySetInnerHTML={{
                  __html: parseText(data?.approach),
                }}
              />
            </div>
            <div
              className="seperator-right"
              dangerouslySetInnerHTML={{
                __html: parseText(data?.content),
              }}
            />
          </div>
        </section>
        <section className="content-section seperator-friendly">
          <h3
            dangerouslySetInnerHTML={{
              __html: parseText(data?.development_flow?.title),
            }}
          />
          <div
            dangerouslySetInnerHTML={{
              __html: parseText(data?.development_flow?.subtitle),
            }}
          />
        </section>
        <section className="element content-section">
          {data?.development_flow?.development_flow?.map((el, index) => {
            const isLeft = index % 2 === 0;
            const scrollClass = scrollStates[index]
              ? isLeft
                ? "item-display"
                : "item-display-right"
              : "";

            return (
              <div
                key={index}
                className={`${
                  isLeft ? "left-of-seperator" : "right-of-seperator"
                } ${index === 2 ? "third-left-child" : ""}`}
              >
                <div
                  className={`${isLeft ? "item" : "item-right"} ${scrollClass}`}
                >
                  <h4
                    dangerouslySetInnerHTML={{
                      __html: parseText(el?.title),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: parseText(el?.description),
                    }}
                  />
                  <p className="services-content">
                    {el?.tasks?.map((ele, indx) => (
                      <p
                        key={indx}
                        className="services-text"
                        dangerouslySetInnerHTML={{
                          __html: parseText(ele?.list),
                        }}
                      />
                    ))}
                  </p>
                </div>
              </div>
            );
          })}
        </section>
        <div className="about-done-that">
          <section className="content-section">
            <h3
              dangerouslySetInnerHTML={{
                __html: parseText(data?.deliverables),
              }}
            />
            <div
              className="about-map-img"
              style={{
                backgroundImage: `url(${data?.industries_image?.url})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 50%",
              }}
            />
          </section>
        </div>
        <div className="about-black">
          <section className="content-section web-only">
            <div>
              <Markdown>{data?.reasons}</Markdown>
            </div>
            <div
              className="reasons"
              style={{
                backgroundImage: `url(${data?.reasons_image?.url})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 0%",
              }}
            ></div>
          </section>
        </div>
        <section className="reason-mobile mobile-only">
          <div className="reason-heading-mobile">
            <Markdown>{data?.reasons}</Markdown>
          </div>
          {data?.reasons_list?.map((el, ind) => (
            <div
              key={ind}
              className={
                ind % 2 === 0
                  ? "triangle-right"
                  : "text-align-right triangle-left"
              }
            >
              <h4>
                <b
                  dangerouslySetInnerHTML={{
                    __html: parseText(el?.title),
                  }}
                />
              </h4>
              <p
                dangerouslySetInnerHTML={{
                  __html: parseText(el?.description),
                }}
              />
            </div>
          ))}
        </section>
      </div>
      <BusinessInquery acheive={acheive} />
      <Footer />
    </div>
  );
};
