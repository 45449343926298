import React, { useEffect, useState } from "react";
import "./header.css";
import { useLocation, useNavigate } from "react-router-dom";

export const Header = () => {
  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [work, setWork] = useState(false);
  const [contact, setContact] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setHome(false);
    setAbout(false);
    setWork(false);
    setContact(false);

    switch (location.pathname) {
      case "/contact":
        setContact(true);
        break;
      case "/about":
        setAbout(true);
        break;
      case "/work":
        setWork(true);
        break;
      default:
        setHome(true);
        break;
    }
  }, [location.pathname]);

  return (
    <nav id="header">
      <p
        className="logo hovertrigger"
        onClick={() => navigate("/")} // Using navigate for smooth navigation
      ></p>
      <ul className="nav web-only">
        <li
          onClick={() => navigate("/")}
          className={"hovertrigger " + (home ? "active-menu" : "")}
        >
          home
        </li>
        <li
          onClick={() => navigate("/about")}
          className={"hovertrigger " + (about ? "active-menu" : "")}
        >
          about
        </li>
        <li
          onClick={() => navigate("/work")}
          className={"hovertrigger " + (work ? "active-menu" : "")}
        >
          work
        </li>
        <li
          onClick={() => navigate("/contact")}
          className={"hovertrigger " + (contact ? "active-menu" : "")}
        >
          contact
        </li>
      </ul>

      <div id="menuToggle" className="mobile-only">
        <input type="checkbox" />

        <span></span>
        <span></span>
        <span></span>

        <ul id="menu">
          <a href="/">
            <li>Home</li>
          </a>
          <a href="/about">
            <li>About</li>
          </a>
          <a href="/work">
            <li>Work</li>
          </a>
          <a href="/contact">
            <li>Contact</li>
          </a>
        </ul>
      </div>
    </nav>
  );
};
