import React from "react";
import "../../styles/main.web.css";
import "../../styles/main.mobile.css";
import { fetchBusinessData } from "../../service/api";
import { parseText } from "../utils";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

export const BusinessInquery = ({ acheive }) => {
  const { data, isLoading, error } = useQuery(
    "businessData",
    fetchBusinessData
  );

  const navigate = useNavigate();

  const toContactPage = () => {
    navigate("/contact");
  };

  return (
    <>
      <div className="grey element">
        <section className="content-section">
          <h5
            className="top-pad-header"
            dangerouslySetInnerHTML={{
              __html: parseText(data?.title),
            }}
          />
          <h3
            dangerouslySetInnerHTML={{
              __html: parseText(data?.subtitle),
            }}
          />
          <ul ref={acheive} className="impact impact-solve movable top-pad-2 ">
            {data?.concepts?.map((el, ind) => (
              <li
                className="wowhide"
                key={ind}
                style={{
                  backgroundImage: `url(${el?.icon?.url})`,
                  backgroundSize: "18%",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "50% 0",
                }}
              >
                <div>
                  <h6
                    dangerouslySetInnerHTML={{
                      __html: parseText(el?.title),
                    }}
                  />
                  <p
                    dangerouslySetInnerHTML={{
                      __html: parseText(el?.description),
                    }}
                  />
                </div>
              </li>
            ))}
          </ul>
          <button onClick={toContactPage}>Lets start a conversation</button>
        </section>
      </div>
      <div className="white-bg relative  padding-bottom-3">
        <section className="content-section element">
          <div className="announcemnet-cover"></div>
          <div className="listening-triangle"></div>
          <div className="announcement"></div>
          <h3
            className="top-pad-header top-pad-header-mobile hiring-top"
            dangerouslySetInnerHTML={{
              __html: parseText(data?.hring_title),
            }}
          />
          <h5
            dangerouslySetInnerHTML={{
              __html: parseText(data?.hiring_location),
            }}
          />
          <div
            className="margintop5 movable"
            dangerouslySetInnerHTML={{
              __html: parseText(data?.primary_content),
            }}
          />
          <button onClick={toContactPage}>Lets talk!</button>
        </section>
      </div>
    </>
  );
};
