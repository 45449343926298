export const businessSet = [
  {
    label: "Business Inquiry",
    id: 1,
    question:
      "Thank you fo your interest in engaging with Rhombuz. How may we help you?",
    choices: [
      {
        id: null,
        heading: "Build a new innovative solution",
        text: "Need help to translate a business requirement to an innovative solution",
        followquestionid: 3,
        answer: {
          heading1: "",
          heading2: "Take an idea to reality",
          text: "Take an idea to reality",
        },
      },
      {
        id: null,
        heading: "Improve an existing system",
        text: "Revamp the existing system/process for better customer experience, efficiency & reduce cost",
        followquestionid: 3,
        answer: {
          heading1: "",
          heading2: "Build a custom solution",
          text: "Take an idea to reality",
        },
      },
      {
        id: null,
        heading: "Develop a novel idea",
        text: "Ideate, prototype and validate a new concept",
        followquestionid: 3,
        answer: {
          heading1: "",
          heading2: "Improve an existing system",
          text: "Improve an existing system",
        },
      },
    ],
  },
];

export const businessEmailSet = [
  {
    label: "Business Inquiry",
    id: null,
    email: true,
    question:
      "<p>Great! please let us know your contact details</p><p> We will connect with you soon!</p>",
  },
];

export const hiringSet = [
  {
    label: "Join the Rhombus Team",
    id: 1,
    question: "Which role suits you best?",
    choices: [
      {
        id: null,
        heading: "UX Designer",
        text: "3-5 years experience, portfolio, design process, problem solving, prototyping, presentation skills",
        followquestionid: 4,
        answer: {
          heading1: "Interested to join as a designer",
          heading2: "",
          text: "",
        },
      },
      {
        id: null,
        heading: "Visual Designer",
        text: "3-5 years experience, portfolio, design process, problem solving, prototyping, presentation skills",
        followquestionid: 4,
        answer: {
          heading1: "Interested to join as a designer",
          heading2: "",
          text: "",
        },
      },
      {
        id: null,
        heading: "Full Stack Developer",
        text: "HTML/CSS, JS, Git and Github, Back-end, Web Architecture, Database Management, Algorithms, React, Nodejs, Devtools, Cloud.",
        followquestionid: 4,
        answer: { heading1: "", heading2: "", text: "" },
      },
      {
        id: null,
        heading: "ML Engineer",
        text: "Machine Learning, Deep Learning, Data Processing, Reinforcement Learning, Neural Networks",
        followquestionid: 4,
        answer: { heading1: "", heading2: "", text: "" },
      },
    ],
  },
];
export const hiringEmailSet = [
  {
    label: "Join the Rhombus Team",
    id: null,
    email: true,
    question:
      "<p>Great! please email your resume to</p><a href='mailto:careers@rhombuz.io'> careers@rhombuz.io</a>",
  },
];

export const hiSet = [
  {
    label: "Just say Hi!",
    id: 1,
    question: "Share a thought, feedback...",
    label: "We will connect with you soon",
    textarea: true,
  },
];
