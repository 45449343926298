import React, { useEffect, useRef, useState } from "react";
import "../styles/main.web.css";
import "../styles/main.mobile.css";
import anim from "../components/masonry/animation";

import scrollDown from "../assets/img/scroll-down-chevron.svg";

import { Footer } from "../components/footer/Footer";
import { BusinessInquery } from "../components/BusinessInquiry/BusinessInquery";
import { fetchHomeData } from "../service/api";
import { parseText } from "../components/utils";
import { handleHeaderScroll } from "../components/utils";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

export const HomePage = () => {
  const magicsection = useRef(null);
  const magiccontent = useRef(null);
  const acheive = useRef(null);
  const wall = useRef(null);
  const viewHeight = window.innerHeight;
  const ecoheading = useRef(null);
  const ecolist = useRef(null);
  const { data, isLoading, error } = useQuery("homeData", fetchHomeData);
  const navigate = useNavigate();
  const [y, setY] = useState(0);

  const handleScroll = (e) => {
    var top = e.target.scrollTop;
    var VH = window.innerHeight;
    const elems = Array.from(wall.current.children);
    handleHeaderScroll(y, top);
    setY(top);

    if (top > VH / 2 && top < VH * 1.6) {
      magicsection.current.className = "magic-section-animate";
      magiccontent.current.className =
        "magic-content web-only magic-section-animate";
      document.getElementById("root").className = "magic-root-before";
    } else {
      magicsection.current.className = "";
      magiccontent.current.className = "magic-content web-only";
    }
    if (top > VH * 2 - 100) {
      anim.wowanimatefadein(ecoheading, false);
      anim.wowanimatefadein(ecolist, true);
    } else {
      anim.wowanimatefadeout(ecoheading, false);
      anim.wowanimatefadeout(ecolist, true);
    }
    if (top > VH * 3 - 100) {
      elems.forEach((element) => {
        element.style.transform = "translate(0, 0)";
        element.style.opacity = "1";
      });
    } else {
      elems.forEach((element) => {
        element.style.transform = "translate(0, 180px)";
        element.style.opacity = "0";
      });
    }

    const businessInquiryElement = acheive.current;
    if (businessInquiryElement) {
      const rect = businessInquiryElement.getBoundingClientRect();
      if (rect.top <= viewHeight * 0.55) {
        anim.wowanimatefadein(acheive, true);
      } else {
        anim.wowanimatefadeout(acheive, true);
      }
    }
    if (top < 600) document.getElementById("root").className = "";
  };

  const toWorkPage = (e) => {
    navigate("/work", { state: { data: e } });
  };

  useEffect(() => {
    if (data) {
      anim.setAnimationScrolldelay(wall);
      anim.setAnimationScrolldelay(acheive);
      anim.setAnimationScrolldelay(ecolist);
    }
  }, [data]);

  return (
    <div id="home-root">
      <div
        onScroll={handleScroll}
        className="container both-scroll both-mandatory align-start"
      >
        <header className="banner fullpage element">
          <div className="scroll-down">
            <p>Scroll Down</p>
            <img src={scrollDown} alt="Scroll down icon" />
          </div>
          <div className="title-header">
            <h1 data-scroll-speed="3">{data?.banner?.title}</h1>
            <ul data-scroll-speed="2" className="nav fusion">
              {data?.banner?.categories.map((el, ind) => (
                <li key={ind}>
                  <p className="transparent">{el?.list}</p>
                  <div
                    className={`animated-title ${
                      ind === 0 ? "first" : ind === 1 ? "second" : "third"
                    }`}
                  >
                    <div className="text-top">
                      <div>
                        <span>{el?.list}</span>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </header>
        <section className="fullpage element">
          <div className="flex-row">
            <div className="magic-section" data-scroll-speed="3">
              <h3
                ref={magicsection}
                dangerouslySetInnerHTML={{
                  __html: parseText(
                    data?.banner?.banner_content[0]?.description
                  ),
                }}
              />
              <p
                className="mobile-only"
                dangerouslySetInnerHTML={{
                  __html: parseText(
                    data?.banner?.banner_content[1]?.description
                  ),
                }}
              />
            </div>
            <div
              ref={magiccontent}
              className="magic-content web-only"
              data-scroll-speed="2"
              dangerouslySetInnerHTML={{
                __html: parseText(data?.banner?.banner_content[1]?.description),
              }}
            />
          </div>
        </section>
        <div className="white-bg">
          <section
            className="content-section fullpage element top-pad-header"
            data-scroll-speed="2"
          >
            <div className="movable">
              <h3
                ref={ecoheading}
                className="wowhide"
                dangerouslySetInnerHTML={{
                  __html: parseText(data?.ecosystem?.title),
                }}
              />
            </div>
            <div
              className="movable"
              dangerouslySetInnerHTML={{
                __html: parseText(data?.ecosystem?.content),
              }}
            />
            <ul ref={ecolist} className="impact movable ">
              {data?.ecosystem?.types.map((el, ind) => (
                <li
                  className="wowhide"
                  key={ind}
                  style={{
                    backgroundImage: `url(${el?.icon?.url})`,
                    backgroundSize: "17%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "50% 0",
                  }}
                >
                  <div>
                    <h6 className="text-uppercase">{el?.title}</h6>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: parseText(el?.description),
                      }}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </section>
        </div>
        <div className="element allow-scroll ">
          <div className="scrollable-div">
            <section className="scalable">
              <div className="content-section">
                <h3 className="mobile-top-spacing">
                  <b>{data?.products?.title}</b>
                </h3>
                <div ref={wall} className="masonry-container element web-only">
                  {data?.products?.product_image?.map((el, ind) => (
                    <div className="masonry-item" key={ind}>
                      <div onClick={() => toWorkPage(el)}>
                        <img
                          src={`${el?.url}`}
                          className="hovertrigger"
                          alt={el?.name}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="products-home mobile-only">
                  {data?.products?.product_image?.map((el) => (
                    <div onClick={() => toWorkPage(el)}>
                      <img src={`${el?.url}`} alt={el?.name} />
                    </div>
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
        <BusinessInquery acheive={acheive} />
        <Footer />
      </div>
    </div>
  );
};
